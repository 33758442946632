import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
//import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
   import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import ProjektPage from "pages/projekt.js"
// import WykonaniePage from "pages/wykonanie.js"
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
   import BranzaDetaliczna from "pages/BranzaDetaliczna.js";
   import BranzaFarmaceutyczna from "pages/BranzaFarmaceutyczna.js";
   import InteligentneBiura from "pages/InteligentneBiura.js";
   import OpisRozwiazania from "pages/OpisRozwiazania.js";
   import Kontakt1 from "pages/Kontakt1.js";
   import CentrumPomocy from "pages/CentrumPomocy.js";
   import Kariera from "pages/Kariera.js"
   //import PolitykaPrywatnosci from "pages/PolitykaPrywatnosci.js";
   //import Rodo from "pages/rodo.js"
   //import RegulaminSerwisu from "pages/RegulaminSerwisu.js"
   import BranzaDetalicznaWiecej from "pages/BranzaDetaliczaWiecej.js"
   import BranzaFarmaceutycznaWiecej from "pages/BranzaFarmaceutycznaWiecej.js";
   import InteligentneBiuraWiecej from "pages/InteligentneBiuraWiecej.js";

// import Form123Page from "pages/Form123.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import OsobistaPage from "pages/osobista.js";
// import ZawodowaPage from "pages/zawodowa.js";
// import FirmowaPage from "pages/firmowa.js";
// import KorporacyjnaPage from "pages/korporacyjna.js";

// import RodoPage from "pages/rodo.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
// import SerwisPage from "pages/serwis.js";

 import ComponentRenderer from "ComponentRenderer.js";
// import MainLandingPage from "MainLandingPage.js";
// import ThankYouPage from "ThankYouPage.js";

import { HashRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <Router>
      <Switch>
      <Route exact path="/">
          <RestaurantLandingPage/>
        </Route>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer/>
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer/>
        </Route>
        {/* <Route path="/RegulaminSerwisu">
          <RegulaminSerwisu/>
        </Route>
        <Route path="/Rodo">
          <Rodo/>
        </Route>
        <Route path="/PolitykaPrywatnosci">
          <PolitykaPrywatnosci/>
        </Route> */}
        <Route exact path="/Kariera">
          <Kariera/>
        </Route>
        <Route exact path="/CentrumPomocy">
          <CentrumPomocy/>
        </Route>
        <Route exact path="/Kontakt">
          <Kontakt1/>
        </Route>
        <Route exact path="/BranzaDetaliczna">
          <BranzaDetaliczna/>
        </Route>
        <Route exact path="/BranzaFarmaceutyczna">
          <BranzaFarmaceutyczna/>
        </Route>
        <Route exact path="/InteligentneBiura">
          <InteligentneBiura/>
        </Route>
        <Route exact path="/OpisRozwiazania">
          <OpisRozwiazania/>
        </Route>
        <Route exact path="/BranzaDetalicznaWiecej">
          <BranzaDetalicznaWiecej/>
        </Route>
        <Route exact path="/BranzaFarmaceutycznaWiecej">
          <BranzaFarmaceutycznaWiecej/>
        </Route>
        <Route exact path="/InteligenteBiuraWiecej">
          <InteligentneBiuraWiecej/>
        </Route>
      </Switch>
    </Router>  
    
  );
}

// export default EventLandingPage;
// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
