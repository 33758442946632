import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { PrimaryLink } from "../headers/light.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto font-sans`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-100 text-center max-w-4xl`;

const Content = tw.div`mt-8`;

const Card = styled.div(props => [
  tw`mt-8 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-contain bg-no-repeat bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8 rounded-3xl`
]);
const Details = tw.div`mt-2 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold text-gray-500 text-xl`;
const Title = tw.h4`text-4xl font-black text-red-600 leading-tight`;

const Description = tw.p`text-sm mt-8 mb-8 leading-normal text-gray-100`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-800 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:text-primary-500`;


const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    // {
    //   imageSrc:
    //     "./logo192.png",
    //   // subtitle: "Korzystaj z najnowszej technologii",
    //   title: "Progressive Web App",
    //   description:
    //     "eWizytówka to nowoczesny i bardzo wygodny sposób komunikacji z klientami lub pracownikami firmy. Aplikację możesz szybko i bez ograniczeń udostępniać nieograniczonej liczbie osób",
    //   // url: "https://timerse.com"
    // },
    // {
    //   imageSrc:
    //     "./2.png",
    //   subtitle: "Korzystaj z najnowszej technologii Progressive Web App.",
    //   title: "Twoja eWizytówka w wygodnej aplikacji.",
    //   description:
    //     "eWizytówka może być zainstalowana (lub dodana do ekranu domowego) na każdym urządzeniu: telefonie, tablecie, laptopie oraz na komputerach stacjonarnych. Działa również przy wolnych połączeniach z internetem oraz w warunkach braku tego połączenia. Posiada certyfikat bezpieczeństwa. Umożliwia błyskawiczne angażowanie Klientów za pomocą niestandardowego linku, kodu QR lub udostępniania społecznościowego. Aplikację możesz szybko i bez ograniczeń udostępniać nieograniczonej liczbie osób. ",
    //   link: "Przykłady eWizytówek",
    //   url: "/wykonanie"
    // },
    
    // {
    //   imageSrc:
    //     "./qr.png",
    //   subtitle: "Wesja demonstracyjna",
    //   title: "Pobierz i sprawdź sam",
    //   description:
    //     "Zeskanuj aparatem w telefonie kod QR i pobierz wersję demonstracyjną eWizytówki. Przekonaj się sam jakie to proste i dołącz do szczęśliwych posiadaczy eWizytówki.",
    //     link: "Zobacz demo",
    //     url: "https://annanowak.ewizytowka.app"
    // },

    // {
    //   imageSrc:
    //     "./3.jpg",
    //   subtitle: "Szybka i sprawna realizacja",
    //   title: "Zamów i bądź eko!",
    //   description:
    //     "eWizytówka może być zainstalowana (lub dodana do ekranu domowego) na każdym urządzeniu: telefonie, tablecie, laptopie oraz na komputerach stacjonarnych. Działa również przy wolnych połączeniach z internetem oraz w warunkach braku tego połączenia. Posiada certyfikat bezpieczeństwa.",
    //     link: "Zamów eWizytówkę",
    //     url: "/zamow"
    // },

    // {
    //   imageSrc:
    //     "./3.jpg",
    //   subtitle: "Korzystaj z najnowszej technologii",
    //   title: "Progressive Web App",
    //   description:
    //     "Aplikacje szybko się ładują, działają w trybie offline i wygodnie zapisują się na telefonie. Są przyjazne w użyciu i zapewniają łatwy dostęp.",
    //   url: "https://timerse.com"
    // },
  ];

  return (
    <Container>
      <SingleColumn>
      {/* <Image style={{ width: 50, height: 50 }} imageSrc="./logoe.png"/>
      {/* <Image imageSrc={"./logoe.png"} />
        <HeadingInfoContainer>
          <HeadingTitle>Mniej papieru, więcej drzew.<br/> Wspieraj środowisko.</HeadingTitle>
          <HeadingDescription>
          <Card>
          <Image style={{ width: 160, height: 160 }} imageSrc="./logoe.png"/>
          <Description>
          eWizytówka to nowoczesny i bardzo wygodny sposób komunikacji z klientami lub pracownikami firmy.
          Aplikację możesz szybko i bez ograniczeń udostępniać nieograniczonej liczbie osób.
          </Description>
          </Card>
          </HeadingDescription>
        </HeadingInfoContainer> */}

        <Content>
        <Card>
          <Image imageSrc="./2.png"/>
          <Details>
          <Subtitle>Korzystaj z najnowszej technologii PWA.</Subtitle>
            <Title style={{ color: "#CF0E37"}}>Twoja <span style={{ color: "white"}}>eWizytówka</span><br/> w wygodnej aplikacji.</Title>
            <Description>
              eWizytówka to bardzo wygodny sposób komunikacji z klientami lub pracownikami firmy.
              Możesz mieć swoją osobistą wizytówkę którą udostępnisz znajomym.
              <br/><br/>
              eWizytówka może być zainstalowana (lub dodana do ekranu początkowego) na każdym
              urządzeniu: telefonie, tablecie, laptopie oraz na komputerze stacjonarnym. Działa również
              przy wolnych połączeniach z internetem oraz w warunkach braku tego połączenia. Posiada
              certyfikat bezpieczeństwa. Umożliwia błyskawiczne angażowanie Klientów za pomocą
              niestandardowego linku, kodu QR lub udostępniania społecznościowego. Aplikację
              możesz szybko i bez ograniczeń udostępniać nieograniczonej liczbie osób.
            </Description>
          </Details>
        </Card>

        <Card>
          <Image imageSrc="./code.png"/>
          <Details>
          <Subtitle>Mniej papieru, więcej drzew, wspieraj środowisko.</Subtitle>
            <Title style={{ color: "#6146F0"}}>Zobacz jak działa <span style={{ color: "white"}}>eWizytówka.</span><br/></Title>
            <Description>
              Zeskanuj aparatem w telefonie kod QR prosto z ekranu komputera lub kliknij:
            </Description>
            {/* <PrimaryButton href="https://annanowak.ewizytowka.app" style={{display: 'block', margin: 'auto'}}>Wersja DEMO >> </PrimaryButton> */}
            <PrimaryLink style={{background: "#6146F0", display: 'block', margin: 'auto', width: 9 + 'rem', borderRadius: 25 +'px', textAlign: 'center', whiteSpace: "nowrap"}} href="https://annanowak.ewizytowka.app">DEMO >></PrimaryLink>
            <Description>
              Przekonaj się sam jakie to proste. eWizytówka szybko, za pomocą jednego kliknięcia 
              jest na ekranie Twojego telefonu, jest przyjazna, przyjemna i bezpieczna w użyciu.
              <br/>
              <br/>
              Możesz podzielić się swoją eWizytówką za pomocą QR kodu, udostępnić ją za pomocą
              e-maila, whatsAppa i Messangera. Podziel sie z innymi swoim Instagramem, Pinterestem,
              Facebookiem, Tik Tokiem czy kanałem You Tube.
              <br/>
              <br/>
              eWizytówka dba o nowoczesny, innowacyjny wizerunek Twój i Twojej firmy.
              Wspiera kreatywność i co najważniejsze dba o nasze środowisko.
            </Description>
          </Details>
        </Card>

        <Card>
          <Image imageSrc="./zamow.png"/>
          <Details>
          <Subtitle>Szybka i sprawna realizacja.</Subtitle>
            <Title style={{ color: "#29D42D"}}>Zamów <span style={{ color: "white"}}>eWizytówkę</span><br/> i bądź eko!</Title>
            <Description>
            Możesz podzielić się swoją eWizytówką za pomocą QR kodu, udostępnić ją za pomocą
              e-maila, whatsAppa i Messangera. Podziel sie z innymi swoim Instagramem, Pinterestem,
              Facebookiem, Tik Tokiem czy kanałem You Tube.
            </Description>
            {/* <PrimaryButton style={{display: 'block', margin: 'auto', background: "#29D42D"}} href="/zamow">Zamów eWizytówkę >></PrimaryButton> */}
            {/* <PrimaryLink style={{background: "#29D42D", display: 'block', margin: 'auto', maxWidth: '300px', borderRadius: 25 +'px', textAlign: 'center'}} href="/zamow">Zamów >></PrimaryLink> */}
            <PrimaryLink style={{background: "#29D42D", display: 'block', margin: 'auto', width: 9 + 'rem', borderRadius: 25 +'px', textAlign: 'center', whiteSpace: "nowrap"}} href="/zamow">Zamów >></PrimaryLink>
            <Card> 
            <Details style={{textAlign: "center"}}>
            <Subtitle style={{ color: "#29D42D"}}>KROK 1</Subtitle>
            <Description>Wypełnienie formularza.<Image style={{ width: 30, height: 50, display: 'block', margin: 'auto' }} imageSrc="./ikon_01.png"/></Description>
            </Details>
            
            <Details style={{textAlign: "center"}}>
            <Subtitle style={{ color: "#29D42D"}}>KROK 2</Subtitle>
            <Description>Wykonanie projektu.<Image style={{ width: 30, height: 50, display: 'block', margin: 'auto' }} imageSrc="./ikon_02.png"/></Description>
            </Details>
      
            <Details style={{textAlign: "center"}}>
            <Subtitle style={{ color: "#29D42D"}}>KROK 3</Subtitle>
            <Description>Aktywacja eWizytówki.<Image style={{ width: 30, height: 50, display: 'block', margin: 'auto' }} imageSrc="./ikon_03.png"/></Description>
            </Details>
            </Card>
          </Details>
        </Card>
        
          {/* {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <PrimaryButton href={card.url}>{card.link}</PrimaryButton>
              </Details>
            </Card>
          ))} */}
        </Content>
      </SingleColumn>
      {/* <SvgDotPattern1 /> */}
      {/* <SvgDotPattern2 /> */}
      {/* <SvgDotPattern3 /> */}
      {/* <SvgDotPattern4 /> */}
    </Container>
  );
};
