import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import LogoImage from "../../images/logo01.svg";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`m-1 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-500 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full mr-6`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("./background.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-0`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-1 mx-auto h-full flex flex-col`;
const Content = tw.div`px-2 flex flex-1 flex-col justify-center items-center`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-center`;
const LogoImg = tw.img`w-screen`;
const LogoText = tw.h5`text-5xl font-normal text-gray-100 text-center tracking-wide`;

const Heading = styled.h1`
  ${tw`text-3xl text-center tracking-wide sm:text-4xl lg:text-5xl xl:text-6xl font-thin text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      {/* <NavLink href="/projekt">
        Wykonanie w 3 krokach
      </NavLink> */}
      <NavLink href="/wykonanie">
        Przykłady
      </NavLink>
      <NavLink href="/cennik">
        Cennik
      </NavLink>
      <NavLink href="/serwis">
        Serwis 24/7
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      {/* <PrimaryLink href="tel:606485873">
        Zadzwoń do nas
      </PrimaryLink> */}
      <PrimaryLink style={{background: "#29D42D", color: "#ffffff"}} href="/zamow">Zamów >></PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
        <LogoContainer>
            <LogoImg src={LogoImage} />
            {/* <LogoImg src="./logoe.png"/>
            <LogoText>wizytówka</LogoText> */}
          </LogoContainer>
          <LogoText>
          {/* more is never enough */}
          {/* creativity is intelligence having fun */}
          </LogoText>
          {/* <PrimaryLink href="/zamow">Zamów eWizytówkę</PrimaryLink> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
