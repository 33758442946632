import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo01.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook1.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram1.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin1.svg";
import { ReactComponent as PinterestIcon } from "../../images/pinterest1.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube1.svg";


const Container = tw.div`relative bg-primary-800 -mx-8 -mb-8 px-8 text-gray-100`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-100 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block rounded-full text-green-500 transition duration-300 mr-1`}
  svg {
    ${tw`w-10 h-10`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            {/* <LogoImg src="./logoe.png"/>
            <LogoText>wizytówka</LogoText> */}
          </LogoContainer>
          <CompanyDescription>
            {/* Nectarina | Advertising Point<br/> */}
            dataGRIT Sp. z o.o.<br/>
            00-137 Warszawa ul. Elektoralna 11<br/>
            NIP:525-287-46-75 KRS:0000918039
          </CompanyDescription>
          <SocialLinksContainer>
            {/* <SocialLink style={{filter: 'brightness(150%)'}} href="https://www.facebook.com/NectarinaAdvertisingPoint"> */}
            <SocialLink style={{filter: 'brightness(150%)'}} href="#">  
              <FacebookIcon />
            </SocialLink>
            {/* <SocialLink style={{filter: 'brightness(150%)'}} href="https://www.instagram.com/nectarina.pl"> */}
            <SocialLink style={{filter: 'brightness(150%)'}} href="#">  
              <InstagramIcon />
            </SocialLink>
            {/* <SocialLink style={{filter: 'brightness(150%)'}} href="https://www.linkedin.com/company/nectarina-advertising-point"> */}
            <SocialLink style={{filter: 'brightness(150%)'}} href="#">  
              <LinkedinIcon />
            </SocialLink>
            {/* <SocialLink style={{filter: 'brightness(150%)'}} href="https://www.youtube.com/channel/UC6EkUQq3K1c3E8LbvoudJng"> */}
            <SocialLink style={{filter: 'brightness(150%)'}} href="#">  
              <YoutubeIcon />
            </SocialLink>
            {/* <SocialLink style={{filter: 'brightness(150%)'}} href="https://pl.pinterest.com/NectarinaAdvertisingPoint/_created"> */}
            <SocialLink style={{filter: 'brightness(150%)'}} href="#">
              <PinterestIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Menu</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/wykonanie">Przykłady</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/cennik">Cennik</Link>
            </LinkListItem>
            <LinkListItem>
            <Link href="/serwis">Serwis 24/7</Link>
            </LinkListItem>
            {/* <LinkListItem>
              <Link href="/pomagaj">Pomagaj z nami</Link>
            </LinkListItem> */}
          </LinkList>
        </Column>
        {/* <Column>
          <ColumnHeading>Przykłady</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/osobista">Osobista</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/zawodowa">Zawodowa</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/firmowa">Firmowa</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/korporacyjna">Korporacyjna</Link>
            </LinkListItem>
          </LinkList>
        </Column> */}
        <Column>
          <ColumnHeading>Zasady</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/rodo">RODO</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/politykaprywatnosci">Polityka</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/regulamin">Regulamin</Link>
            </LinkListItem>
            {/* <LinkListItem>
              <Link href="/serwis">Serwis 24/7</Link>
            </LinkListItem> */}
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
