import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-white text-black`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-400 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-black`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-gray-400 hover:border-black transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-600`;

export default ({
  subheading = "",
  heading = "FAQ",
  description = "Zapraszamy do zapoznania się z najczęściej zadawanymi pytaniami i odpowiedziami.",
  faqs = [
    {
      question: "Czy elektroniczne etykiety cenowe są łatwe w obsłudze?",
      answer:
        "Tak, większość systemów ESL jest zaprojektowana tak, aby była intuicyjna i łatwa do obsługi. Aktualizowanie etykiet cenowych odbywa się centralnie z jednego miejsca, za pomocą dedykowanego oprogramowania. Zmiany w cenach, opisach i innych informacjach można wprowadzać za pomocą kilku kliknięć."
    },
    {
      question: "Czy elektroniczne etykiety są bezpieczne dla klientów?",
      answer:
        "Tak, elektroniczne etykiety cenowe są bezpieczne zarówno dla klientów, jak i pracowników sklepów. Używają one technologii pasywnych wyświetlaczy e-ink, które nie emitują szkodliwego promieniowania ani nie wytwarzają nadmiernego ciepła. Dodatkowo, etykiety są odporne na uszkodzenia, a ich żywotność jest znacznie dłuższa niż tradycyjnych papierowych etykiet."
    },
    {
      question: "Czy etykiety elektroniczne mogą wyświetlać tylko ceny?",
      answer:
        "Nie, elektroniczne etykiety cenowe mogą wyświetlać różne informacje, takie jak: Cena produktu, Opis produktu, Kody QR i kody kreskowe, Stany magazynowe, Informacje o promocjach i rabatach, Opinie i recenzje klientów Etykiety mogą być dostosowane do różnych potrzeb sklepu i branży, umożliwiając wyświetlanie szerokiego zakresu informacji."
    },
    {
      question: "Czy elektroniczne etykiety cenowe są kosztowne?",
      answer:
        "Początkowy koszt zakupu systemu ESL (etykiet, oprogramowania, infrastruktury) może być stosunkowo wysoki. Jednak w dłuższym okresie korzyści, takie jak oszczędności na materiałach, redukcja czasu poświęconego na ręczne aktualizowanie cen oraz poprawa efektywności operacyjnej, sprawiają, że inwestycja w ESL może być opłacalna."
    },
    {
      question: "Jak działają elektroniczne etykiety cenowe?",
      answer:
        "Etykiety cenowe działają dzięki technologii e-ink, która wykorzystuje mikrokapsułki z cząstkami czarnego i białego atramentu. Zmiana obrazu na etykiecie jest możliwa dzięki zasilaniu elektrycznemu, co umożliwia szybkie i wygodne aktualizowanie treści. Etykiety są zazwyczaj połączone z centralnym systemem zarządzania, który pozwala na zdalne wprowadzanie zmian cen i innych informacji w całym sklepie lub sieci sklepów."
    },
    {
      question: "Czy firma zapewnia wsparcie po montażu?",
      answer:
        "Zdalne wsparcie użytkownika w kontekście elektronicznych etykiet cenowych jest kluczowe dla zapewnienia ich niezawodności i efektywności operacyjnej. Dzięki centralnemu zarządzaniu, łatwym aktualizacjom oprogramowania, szybkiej diagnostyce i monitorowaniu stanu urządzeń, sklepy mogą minimalizować czas przestojów i utrzymanie systemu na najwyższym poziomie. Zdalne wsparcie jest również istotnym elementem optymalizacji kosztów i zwiększenia efektywności operacyjnej, co sprawia, że jest to niezbędny element dla nowoczesnych sieci handlowych.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
