import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-100 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-100`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Polityka prywatności" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Ostatnia akutalizacja: 18 luty 2022 r.</p>

            <p>Polityka prywatności serwisu www.ewizytowka.app</p>

            <h2>Punkt 1</h2>
            <p>
            Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich z usługi wykonania eWizytówki przez Serwis. 
            </p>

            <h2>Punkt 2</h2>
            <p>Administratorem danych osobowych zawartych w serwisie jest dataGRIT Sp. z o.o. z siedzibą w 00-137 Warszawa ul. Elektoralna 11 KRS:0000918039 NIP:525-287-46-75.</p>

            <h2>Punkt 3.</h2>
            <p>
            W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego. 
            </p>

            <h2>Punkt 4</h2>
            <p>Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.</p>

            <h2>Punkt 5</h2>
            <p>
            Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób: 
            <ul>
              <li>poprzez dobrowolnie wprowadzone w formularzach informacje</li>
              <li>poprzez gromadzenie plików “cookies” [patrz polityka plików “cookies”]. </li>
            </ul>
            </p>

            <h2>Punkt 6</h2>
            <p>Serwis zbiera informacje dobrowolnie podane przez użytkownika.</p>

            <h2>Punkt 7</h2>
            <p>
            Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza np. w celu dokonania procesu obsługi kontaktu informacyjnego.
            </p>

            <h2>Punkt 8</h2>
            <p>
            Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.
            </p>

            <h2>Punkt 9</h2>
            <p>
            Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również prawo do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie. 
            </p>

            <h2>Punkt 10</h2>
            <p>
            Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.
            </p>
            
            <h2>Punkt 11</h2>
            <p>
            W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony internetowe działają niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez serwis www.eWizytowka.app 
            </p>
            <p>
            Strony te mogą posiadać własne polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się zapoznać.
            </p>
            
            <h3>W razie wątpliwości do któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do dyspozycji - nasze dane znaleźć można w zakładce - SERWIS 24/7.</h3>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
