import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-100 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-100`}
  p {
    ${tw`mt-2 leading-loose text-gray-100`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Regulamin serwisu" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Ostatnia aktualizacja: 18 luty 2022 r.</p>

            <h2>§1. Postanowienia wstępne</h2>
            <p>
            1. Sklep internetowy www.ewizytowka.app, prowadzony jest przez dataGRIT Sp. z o.o. z siedzibą w 00-137 Warszawa ul. Elektorlana 11 wpisanego do Krajowego Rejestru Sądowego przez Sąd Rejonowy w Warszawie, XII Wydział Gospodarczy KRS pod numerem KRS 0000918039, o kapitale zakładowym 5000 zł, NIP 5252874675.  
            </p>
            <p>
            2. Niniejszy Regulamin skierowany jest zarówno do Konsumentów, jak i do Przedsiębiorców korzystających ze Sklepu i określa zasady korzystania ze Sklepu internetowego oraz zasady i tryb zawierania Umów Sprzedaży z Klientem na odległość za pośrednictwem Sklepu. 
            </p>

            <h2>§2. Definicje</h2>
            <ul>
              <li>
                <strong>Konsument</strong> - osoba fizyczna zawierająca ze Sprzedawcą umowę w ramach Sklepu, której przedmiot nie jest związany bezpośrednio z jej działalnością gospodarczą lub zawodową. 
              </li>
              <li>
                <strong>Sprzedawca</strong> - dataGRIT Sp. z o.o. z siedzibą w 00-137 Warszawa ul. Elektorlana 11 wpisanego do Krajowego Rejestru Sądowego przez Sąd Rejonowy w Warszawie, XII Wydział Gospodarczy KRS pod numerem KRS 0000918039, o kapitale zakładowym 5000 zł, NIP 5252874675.
              </li>
              <li>
                <strong>Klient</strong> - każdy podmiot dokonujący zakupów za pośrednictwem Sklepu.
              </li>
              <li>
                <strong>Przedsiębiorca</strong> - osoba fizyczna, osoba prawna i jednostka organizacyjna niebędąca osobą prawną, której odrębna ustawa przyznaje zdolność prawną, wykonująca we własnym imieniu działalność gospodarczą, która korzysta ze Sklepu.
              </li>
              <li>
                <strong>Sklep</strong> - sklep internetowy prowadzony przez Sprzedawcę pod adresem internetowym www.ewizytowka.app
              </li>
              <li>
                <strong>Umowa zawarta na odległość</strong> - umowa zawarta z Klientem w ramach zorganizowanego systemu zawierania umów na odległość (w ramach Sklepu), bez jednoczesnej fizycznej obecności stron, z wyłącznym wykorzystaniem jednego lub większej liczby środków porozumiewania się na odległość do chwili zawarcia umowy włącznie.

              </li>
              <li>
                <strong>Regulamin</strong> - niniejszy regulamin Sklepu.
              </li>
              <li>
                <strong>Zamówienie</strong> - oświadczenie woli Klienta składane za pomocą Formularza Zamówienia i zmierzające bezpośrednio do zawarcia Umowy Sprzedaży Produktu lub Produktów ze Sprzedawcą.
              </li>
              <li>
                <strong>Konto</strong> - konto klienta w Sklepie, są w nim gromadzone są dane podane przez Klienta oraz informacje o złożonych przez niego Zamówieniach w Sklepie.
              </li>
              <li>
                <strong>Formularz rejestracji</strong> - formularz dostępny w Sklepie, umożliwiający utworzenie Konta.
              </li>
              <li>
                <strong>Formularz zamówienia</strong> - interaktywny formularz dostępny w Sklepie umożliwiający złożenie Zamówienia, w szczególności poprzez dodanie Produktów do Koszyka oraz określenie warunków Umowy Sprzedaży, w tym sposobu dostawy i płatności.
              </li>
              {/* <li>
                <strong>Koszyk</strong> – element oprogramowania Sklepu, w którym widoczne są wybrane przez Klienta Produkty do zakupu, a także istnieje możliwość ustalenia i modyfikacji danych Zamówienia, w szczególności ilości produktów.
              </li> */}
              <li>
                <strong>Produkt</strong> - dostępna w Sklepie rzecz ruchoma/usługa będąca przedmiotem Umowy Sprzedaży między Klientem a Sprzedawcą.
              </li>
              <li>
                <strong>Umowa Sprzedaży</strong> - umowa sprzedaży Produktu zawierana albo zawarta między Klientem a Sprzedawcą za pośrednictwem Sklepu internetowego. Przez Umowę Sprzedaży rozumie się też - stosowanie do cech Produktu - umowę o świadczenie usług i umowę o dzieło.
              </li>
            </ul>

            <h2>§3. Kontakt ze Sklepem</h2>
            <p>
            Adres Sprzedawcy: 00-137 Warszawa ul. Elektoralna 11
            </p>
            <p>
            Adres e-mail Sprzedawcy: kontakt@nectarina.pl
            </p>
            <p>
            Numer telefonu Sprzedawcy: +48 606 485 873
            </p>
            <p>
            Numer rachunku bankowego Sprzedawcy: mbank 46 1140 2004 0000 3302 8164 5969
            </p>
            <p>
            Klient może porozumiewać się ze Sprzedawcą za pomocą adresów i numerów telefonów podanych w niniejszym paragrafie.
            </p>
            <p>
            Klient może porozumieć się telefonicznie ze Sprzedawcą w godzinach: 9-17 od poniedziałku do piątku.
            </p>

            <h2>§4. Wymagania techniczne</h2>
            <p>
            Do korzystania ze Sklepu, w tym przeglądania asortymentu Sklepu oraz składania zamówień na Produkty, niezbędne są: 
            urządzenie końcowe z dostępem do internetu,
            aktywne konto poczty elektronicznej (e-mail),
            włączona obsługa plików cookies.
            </p>

            <h2>§5. Informacje ogólne</h2>
            <p>
            1. Sprzedawca w najszerszym dopuszczalnym przez prawo zakresie nie ponosi odpowiedzialności za zakłócenia w tym przerwy w funkcjonowaniu Sklepu spowodowane siłą wyższą, niedozwolonym działaniem osób trzecich lub niekompatybilnością Sklepu internetowego z infrastrukturą techniczną Klienta. 
            </p>
            <p>
            2. Przeglądanie asortymentu Sklepu nie wymaga zakładania Konta. Składanie zamówień przez Klienta na Produkty znajdujące się w asortymencie Sklepu możliwe jest albo po założeniu Konta zgodnie z postanowieniami § 6 Regulaminu albo przez podanie niezbędnych danych osobowych i adresowych umożliwiających realizację Zamówienia bez zakładania Konta. 
            </p>
            <p>
            3. Ceny podane w Sklepie są podane w polskich złotych i są cenami brutto (uwzględniają podatek VAT).
            </p>
            <p>
            4. Na końcową (ostateczną) kwotę do zapłaty przez Klienta składa się cena za Produkt oraz koszt dostawy (w tym opłaty za transport, dostarczenie i usługi pocztowe), o której Klient jest informowany na stronach Sklepu w trakcie składania Zamówienia, w tym także w chwili wyrażenia woli związania się Umową Sprzedaży.
            </p>
            <p>
            5. W przypadku Umowy obejmującej prenumeratę lub świadczenie usług na czas nieoznaczony końcową (ostateczną) ceną jest łączna cena obejmująca wszystkie płatności za okres rozliczeniowy.
            </p>
            <p>
            6. Gdy charakter przedmiotu Umowy nie pozwala, rozsądnie oceniając, na wcześniejsze obliczenie wysokości końcowej (ostatecznej) ceny, informacja o sposobie, w jaki cena będzie obliczana, a także o opłatach za transport, dostarczenie, usługi pocztowe oraz o innych kosztach, będzie podana w Sklepie w opisie Produktu.
            </p>

            <h2>§6. Zakładanie Konta w Sklepie</h2>
            <p>
            1. Aby założyć Konto w Sklepie, należy wypełnić Formularz rejestracji. Niezbędne jest podanie następujących danych: imię i nazwisko oraz adres e-mail.
            </p>
            <p>
            2. Założenie Konta w Sklepie jest darmowe.
            </p>
            <p>
            3. Logowanie się na Konto odbywa się poprzez podanie loginu i hasła ustanowionych w Formularzu rejestracji.
            </p>
            <p>
            4. Klient ma możliwość w każdej chwili, bez podania przyczyny i bez ponoszenia z tego tytułu jakichkolwiek opłat usunąć Konto poprzez wysłanie stosownego żądania do Sprzedawcy, w szczególności za pośrednictwem poczty elektronicznej lub pisemnie na adresy podane w § 3.
            </p>

            <h2>§7. Zasady składania Zamówienia</h2>
            <p>
            W celu złożenia Zamówienia należy: 
              <p>1. zalogować się do Sklepu (opcjonalnie);</p>
              <p>2. wybrać Produkt będący przedmiotem Zamówienia, a następnie kliknąć przycisk „Do koszyka” (lub równoznaczny);</p>
              <p>3. zalogować się lub skorzystać z możliwości złożenia Zamówienia bez rejestracji;</p>
              <p>4. jeżeli wybrano możliwość złożenia Zamówienia bez rejestracji - wypełnić Formularz zamówienia poprzez wpisanie danych odbiorcy Zamówienia oraz adresu, na który ma nastąpić dostawa Produktu, wybrać rodzaj przesyłki (sposób dostarczenia Produktu), wpisać dane do faktury, jeśli są inne niż dane odbiorcy Zamówienia;</p>
              <p>5. kliknąć przycisk “Zamawiam i płacę”/kliknąć przycisk “Zamawiam i płacę” oraz potwierdzić zamówienie, klikając w link przesłany w wiadomości e-mail;</p>
              <p>6. wybrać jeden z dostępnych sposobów płatności i w zależności od sposobu płatności, opłacić zamówienie w określonym terminie, z zastrzeżeniem § 8 pkt 3.</p>
            </p>
            
            <h2>§8. Oferowane metody dostawy oraz płatności</h2>
            <p>
            1. Produktu wysyłany jest drogą elektorniczną (email lub sms).
            </p>
            <p>
            2. Klient płaci za produkt na podstawie wystawionej faktury VAT przelewem na rachunek bankowy.
            </p>
            <p>
            3. Szczegółowe informacje na temat metod dostawy oraz akceptowalnych metod płatności znajdują się za stronach Sklepu.
            </p>  

            <h2>§9. Wykonanie umowy sprzedaży</h2>
            <p>
            1. Zawarcie Umowy Sprzedaży między Klientem a Sprzedawcą następuje po uprzednim złożeniu przez Klienta Zamówienia za pomocą Formularza zamówienia w Sklepie internetowym zgodnie z § 7 Regulaminu.
            </p>
            <p>
            2. Po złożeniu Zamówienia Sprzedawca niezwłocznie potwierdza jego otrzymanie oraz jednocześnie przyjmuje Zamówienie do realizacji. Potwierdzenie otrzymania Zamówienia i jego przyjęcie do realizacji następuje poprzez przesłanie przez Sprzedawcę Klientowi stosownej wiadomości e-mail na podany w trakcie składania Zamówienia adres poczty elektronicznej Klienta, która zawiera co najmniej oświadczenia Sprzedawcy o otrzymaniu Zamówienia i o jego przyjęciu do realizacji oraz potwierdzenie zawarcia Umowy Sprzedaży. Z chwilą otrzymania przez Klienta powyższej wiadomości e-mail zostaje zawarta Umowa Sprzedaży między Klientem a Sprzedawcą.
            </p>
            <p>
            3. W przypadku wyboru przez Klienta: 
            </p>
            <p>
            a) płatności przelewem, płatności elektronicznych albo płatności kartą płatniczą, Klient obowiązany jest do dokonania płatności w terminie 3 dni kalendarzowych od dnia zawarcia Umowy Sprzedaży - w przeciwnym razie zamówienie zostanie anulowane.
            </p>
            <p>
            b) płatności za pobraniem przy odbiorze przesyłki, Klient obowiązany jest do dokonania płatności przy odbiorze przesyłki.
            </p>
            <p>
            c) płatności gotówką przy odbiorze osobistym przesyłki, Klient obowiązany jest dokonać płatności przy odbiorze przesyłki w terminie 3 dni od dnia otrzymania informacji o gotowości przesyłki do odbioru.
            </p>
            <p>
            4. Jeżeli Klient wybrał sposób dostawy inny niż odbiór osobisty, Produkt zostanie wysłany przez Sprzedawcę w terminie wskazanym w jego opisie (z zastrzeżeniem ustępu 5 niniejszego paragrafu), w sposób wybrany przez Klienta podczas składania Zamówienia. 
            </p>
            <p>
            5. A W przypadku zamówienia Produktów o różnych terminach dostawy, terminem dostawy jest najdłuższy podany termin.<br/>
              B W przypadku zamówienia Produktów o różnych terminach dostawy, Klient ma możliwość żądania dostarczenia Produktów częściami lub też dostarczenia wszystkich Produktów po skompletowaniu całego zamówienia.
            </p>
            {/* <p>
            Początek biegu terminu dostawy Produktu do Klienta liczy się w następujący sposób:
            </p>
            <p>
            a) w przypadku wyboru przez Klienta sposobu płatności przelewem, płatności elektroniczne lub kartą płatniczą - od dnia uznania rachunku bankowego Sprzedawcy,
            </p>
            <p>
            b) w przypadku wyboru przez Klienta sposobu płatności za pobraniem – od dnia zawarcia Umowy Sprzedaży,
            </p> */}
            <p>
            6. W przypadku wyboru przez Klienta odbioru osobistego Produktu, Produkt będzie gotowy do odbioru przez Klienta w terminie wskazanym w opisie Produktu. O gotowości Produktu do odbioru Klient zostanie dodatkowo poinformowany przez Sprzedawcę poprzez przesłanie stosownej wiadomości e-mail na podany w trakcie składania Zamówienia adres poczty elektronicznej Klienta.
            </p>
            <p>
            7. W przypadku zamówienia Produktów o różnych terminach gotowości do odbioru, terminem gotowości do odbioru jest najdłuższy podany termin. 
            </p>
            <p>
            8. Początek biegu terminu gotowości Produktu do odbioru przez Klienta liczy się w następujący sposób:
            </p>
            <p>
            a) w przypadku wyboru przez Klienta sposobu płatności przelewem, płatności elektroniczne lub kartą płatniczą - od dnia uznania rachunku bankowego Sprzedawcy.
            </p>
            <p>
            b) w przypadku wyboru przez Klienta sposobu gotówką przy odbiorze osobistym – od dnia zawarcia Umowy Sprzedaży.
            </p>
            <p>
            9. Dostawa Produktu odbywa się na terenie Unii Europejskiej, Wielkiej Brytanii oraz Stanów Zjednoczonych.
            </p>
            <p>
            10. Dostawa Produktu do Klienta jest bezpłatna, chyba że Umowa Sprzedaży stanowi inaczej.
            </p>
            {/* <p>
            11. Odbiór osobisty Produktu przez Klienta jest bezpłatny. 
            </p> */}

            <h2>§10. Prawo odstąpienia od umowy</h2>
            <p>
            1. Konsument może w terminie 14 dni odstąpić od Umowy Sprzedaży bez podania jakiejkolwiek przyczyny.
            </p>
            <p>
            2. Bieg terminu określonego w ust. 1 rozpoczyna się od dostarczenia Produktu Konsumentowi lub wskazanej przez niego osobie innej niż przewoźnik.
            </p>
            <p>
            3. W przypadku Umowy, która obejmuje wiele Produktów, które są dostarczane osobno, partiami lub w częściach, termin wskazany w ust. 1 biegnie od dostawy ostatniej rzeczy, partii lub części.
            </p>
            <p>
            4. W przypadku Umowy, która polega na regularnym dostarczaniu Produktów przez czas oznaczony (prenumerata), termin wskazany w ust. 1 biegnie od objęcia w posiadanie pierwszej z rzeczy.
            </p>
            <p>
            5. Konsument może odstąpić od Umowy, składając Sprzedawcy oświadczenie o odstąpieniu od Umowy. Do zachowania terminu odstąpienia od Umowy wystarczy wysłanie przez Konsumenta oświadczenia przed upływem tego terminu.
            </p>
            <p>
            6. Oświadczenie może być wysłane za pomocą tradycyjnej poczty, faxem bądź drogą elektroniczną poprzez przesłanie oświadczenia na adres e-mail Sprzedawcy lub przez złożenie oświadczenia na stronie internetowej Sprzedawcy - dane kontaktowe Sprzedawcy zostały określone w § 3. Oświadczenie można złożyć również na formularzu, którego wzór stanowi załącznik nr 1 do niniejszego Regulaminu oraz załącznik do ustawy z dnia 30 maja 2014 roku o prawach konsumenta, jednak nie jest to obowiązkowe.
            </p>
            <p>
            7. W przypadku przesłania oświadczenia przez Konsumenta drogą elektroniczną, Sprzedawca niezwłocznie prześle Konsumentowi na podany przez Konsumenta adres e-mail potwierdzenie otrzymania oświadczenia o odstąpieniu od Umowy.
            </p>
            <p>
            8. Skutki odstąpienia od Umowy:
            </p>
            <p>
            a) W przypadku odstąpienia od Umowy zawartej na odległość Umowę uważa się za niezawartą.
            </p>
            <p>
            b) W przypadku odstąpienia od Umowy Sprzedawca zwraca Konsumentowi niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od Umowy, wszystkie dokonane przez niego płatności, w tym koszty dostarczenia rzeczy, z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Konsumenta sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez Sprzedawcę.
            </p>
            <p>
            c) Zwrotu płatności Sprzedawca dokona przy użyciu takich samych metod płatności, jakie zostały przez Konsumenta użyte w pierwotnej transakcji, chyba że Konsument wyraźnie zgodził się na inne rozwiązanie, które nie będzie się wiązało dla niego z żadnymi kosztami.
            </p>
            <p>
            d) Sprzedawca może wstrzymać się ze zwrotem płatności do czasu otrzymania Produktu z powrotem lub do czasu dostarczenia mu dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.
            </p>
            <p>
            e) Konsument powinien odesłać Produkt na adres Sprzedawcy podany w niniejszym Regulaminie niezwłocznie, nie później niż 14 dni od dnia, w którym poinformował Sprzedawcę o odstąpieniu od Umowy. Termin zostanie zachowany, jeśli Konsument odeśle Produkt przed upływem terminu 14 dni.
            </p>
            <p>
            f) Konsument ponosi bezpośrednie koszty zwrotu Produktu, także koszty zwrotu Produktu, jeśli ze względu na swój charakter Produkt ten nie mógł zostać w zwykłym trybie odesłany pocztą.
            </p>
            <p>
            g) Konsument odpowiada tylko za zmniejszenie wartości Produktu wynikające z korzystania z niego w sposób inny niż było to konieczne do stwierdzenia charakteru, cech i funkcjonowania Produktu.
            </p>
            <p>
            9. W przypadku gdy ze względu na charakter Produktu nie może on zostać odesłany w zwykłym trybie pocztą, informacja o tym, a także o kosztach zwrotu Produktu, będzie się znajdować w opisie Produktu w Sklepie.
            </p>
            <p>
            10. Prawo do odstąpienia od umowy zawartej na odległość nie przysługuje Konsumentowi w odniesieniu do Umowy:
            </p>
            <p>
            a) w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji Konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb,
            </p>
            <p>
            b) w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu,
            </p>
            <p>
            c) w które przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia,
            </p>
            <p>
            d) o świadczenie usług, jeżeli Sprzedawca wykonał w pełni usługę za wyraźną zgodą Konsumenta, który został poinformowany przez rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Sprzedawcę utraci prawo odstąpienia od Umowy,
            </p>
            <p>
            e) w którym cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którym Sprzedawca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od Umowy,
            </p>
            <p>
            f) w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami,
            </p>
            <p>
            g) w której przedmiotem świadczenia są napoje alkoholowe, których cena została uzgodniona przy zawarciu umowy sprzedaży, a których dostarczenie może nastąpić dopiero po upływie 30 dni i których wartość zależy od wahań na rynku, nad którymi Sprzedawca nie ma kontroli,
            </p>
            <p>
            h) w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu,
            </p>
            <p>
            i) o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o prenumeratę,
            </p>
            <p>
            j) o dostarczenie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez Sprzedawcę o utracie prawa odstąpienia od Umowy.
            </p>

            <h2>§11. Reklamacja i gwarancja</h2>
            <p>
            1. Umową Sprzedaży objęte są nowe Produkty.
            </p>
            <p>
            2. Sprzedawca jest obowiązany dostarczyć Klientowi rzecz wolną od wad.
            </p>
            <p>
            3. W przypadku wystąpienia wady zakupionego u Sprzedawcy towaru Klient ma prawo do reklamacji w oparciu o przepisy dotyczące rękojmi w kodeksie cywilnym. 
            </p>
            <p>
            4. Reklamację należy zgłosić pisemnie lub drogą elektroniczną na podane w niniejszym Regulaminie adresy Sprzedawcy. 
            </p>
            <p>
            5. Zaleca się, aby w reklamacji zawrzeć m.in. zwięzły opis wady, okoliczności (w tym datę) jej wystąpienia, dane Klienta składającego reklamację, oraz żądanie Klienta w związku z wadą towaru. 
            </p>
            <p>
            6. Sprzedawca ustosunkuje się do żądania reklamacyjnego niezwłocznie, nie później niż w terminie 14 dni, a jeśli nie zrobi tego w tym terminie, uważa się, że żądanie Klienta uznał za uzasadnione.
            </p>
            {/* <p>
            7. Towary odsyłane w ramach procedury reklamacyjnej należy wysyłać na adres: 
            </p> */}
            <p>
            8. W przypadku, gdy na Produkt została udzielona gwarancja, informacja o niej, a także jej treść, będą zawarte przy opisie Produktu w Sklepie. 
            </p>
            
            <h2>§12. Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń</h2>
            <p>
            1. Szczegółowe informacje dotyczące możliwości skorzystania przez Konsumenta z  pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów: http://www.uokik.gov.pl/spory_konsumenckie.php; http://www.uokik.gov.pl/sprawy_indywidualne.php oraz http://www.uokik.gov.pl/wazne_adresy.php.
            </p>
            <p>
            2. Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:
            </p>
            <p>
            a) Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o rozstrzygnięcie sporu wynikłego z Umowy zawartej ze Sprzedawcą. 
            </p>
            <p>
            b) Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu między Konsumentem a Sprzedawcą. 
            </p>
            <p>
            c) Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między nim a Sprzedawcą, korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).
            </p>
            
            <h2>§13. Dane osobowe w Sklepie internetowym</h2>
            <p>
            1. Administratorem danych osobowych Klientów zbieranych za pośrednictwem Sklepu internetowego jest Sprzedawca.
            </p>
            <p>
            2. Dane osobowe Klientów zbierane przez administratora za pośrednictwem Sklepu internetowego zbierane są w celu realizacji Umowy Sprzedaży, a jeżeli Klient wyrazi na to zgodę - także w celu marketingowym.
            </p>
            <p>
            3. Odbiorcami danych osobowych Klientów Sklepu internetowego mogą być: 
            </p>
            <p>
            a) W przypadku Klienta, który korzysta w Sklepie internetowym ze sposobu dostawy przesyłką pocztową lub przesyłką kurierską, Administrator udostępnia zebrane dane osobowe Klienta wybranemu przewoźnikowi lub pośrednikowi realizującemu przesyłki na zlecenie Administratora.
            </p>
            <p>
            b) W przypadku Klienta, który korzysta w Sklepie internetowym ze sposobu płatności elektronicznych lub kartą płatniczą Administrator udostępnia zebrane dane osobowe Klienta, wybranemu podmiotowi obsługującemu powyższe płatności w Sklepie internetowym. 
            </p>
            <p>
            4. Klient ma prawo dostępu do treści swoich danych oraz ich poprawiania.
            </p>
            <p>
            5. Podanie danych osobowych jest dobrowolne, aczkolwiek niepodanie wskazanych w Regulaminie danych osobowych niezbędnych do zawarcia Umowy Sprzedaży skutkuje brakiem możliwości zawarcia tejże umowy. 
            </p>


            <h2>§14. Postanowienia końcowe</h2>
            <p>
            1. Umowy zawierane poprzez Sklep internetowy zawierane są w języku polskim.
            </p>
            <p>
            2. Sprzedawca zastrzega sobie prawo do dokonywania zmian Regulaminu z ważnych przyczyn to jest: zmiany przepisów prawa, zmiany sposobów płatności i dostaw - w zakresie, w jakim te zmiany wpływają na realizację postanowień niniejszego Regulaminu. O każdej zmianie Sprzedawca poinformuje Klienta z co najmniej 7 dniowym wyprzedzeniem.
            </p>
            <p>
            3. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług drogą elektroniczną; ustawy o prawach konsumenta, ustawy o ochronie danych osobowych. 
            </p>
            <p>
            4. Klient ma prawo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. W tym celu może złożyć skargę za pośrednictwem unijnej platformy internetowej ODR dostępnej pod adresem: http://ec.europa.eu/consumers/odr/.
            </p>

            <h2>Skontaktuj się z nami</h2>
            <p>Jeśli masz jakieś pytania dotyczace regulaminu bardzo prosimy o kontakt:</p>
            <ul>
              <li>e-mail: kontakt@nectarina.pl</li>
              <li>telefon: 606485873</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
