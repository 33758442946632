import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-black px-2 py-2 leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-10 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-200 font-medium transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-[#fc0606]! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-black block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover border relative`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-white`;
const CardTitle = tw.h5`text-lg font-semibold`;
const CardContent = tw.p`mt-1 text-base text-white`;
const CardPrice = tw.p`mt-1 text-base text-white`;
const CardPrice2 = tw.p`mt-1 text-base text-white`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Dostępne serie i rozmiary:",
  tabs = {
    Podstawowe: [
      {
        imageSrc:"Z1.54.png",
        title: "e-cenówka 1.54''",
        content: "rozmiar: 49x37.6x13 mm",
        price: "ekran: 27.5x27.5 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z1.54.png"
      }, 
      {
        imageSrc:"Z2.13.png",
        title: "e-cenówka 2.13''",
        content: "rozmiar: 71.3x35.6x12 mm",
        price: "ekran: 48.6x23.7 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z2.13.png"
      },
      {
        imageSrc:"Z2.66.png",
        title: "e-cenówka 2.66''",
        content: "rozmiar: 85.8x41.8x12 mm",
        price: "ekran: 60.1x30.7 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z2.66.png"
      },
      {
        imageSrc:"Z2.9.png",
        title: "e-cenówka 2.9''",
        content: "rozmiar: 91.2x41x12 mm",
        price: "ekran: 66.9x29.1 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z2.9.png"
      },
      {
        imageSrc:"Z3.5.png",
        title: "e-cenówka 3.5''",
        content: "rozmiar: 100.5x48.5x12 mm",
        price: "ekran: 79.7x38 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z3.5.png"
      },
      {
        imageSrc:"Z4.2.png",
        title: "e-cenówka 4.2''",
        content: "rozmiar: 99.2x89x12 mm",
        price: "ekran: 84.8x63.6 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z4.2.png"
      },
      {
        imageSrc:"Z4.3.png",
        title: "e-cenówka 4.3''",
        content: "rozmiar: 129x42x12 mm",
        price: "ekran: 106x31.5 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z4.3.png"
      },
      {
        imageSrc:"Z5.8.png",
        title: "e-cenówka 5.8''",
        content: "rozmiar: 134.5x114x9 mm",
        price: "ekran: 118.8x88.2 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z5.8.png"
      },
      {
        imageSrc:"Z7.5.png",
        title: "e-cenówka 7.5''",
        content: "rozmiar: 178x127x9 mm",
        price: "ekran: 163.2x97.9 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z7.5.png"
      },
      {
        imageSrc:"Z12.5.png",
        title: "e-cenówka 10.2''",
        content: "rozmiar: 233x170.90x9 mm",
        price: "ekran: 221.52x143.68 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z12.5.png"
      },
      {
        imageSrc:"Z13.3.png",
        title: "e-cenówka 12.5''",
        content: "rozmiar: 281.3x229x17.8 mm",
        price: "ekran: 253x191 mm",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z13.3.png"
      },
      {
        imageSrc:"Z10.2.png",
        title: "e-cenówka 13.3''",
        content: "rozmiar: 294.72x229.56x19 mm",
        price: "ekran: 252.97x190.89",
        price2: "kolory: BW/BWR/BWRY",
        reviews: "87",
        url: "Z10.2.png"
      }
    ],
    Lodówkowe: [
      {
        imageSrc:"Z2.13L.png",
        title: "e-cenówka 2.13''",
        content: "rozmiar: 71.3x35.6x12 mm",
        price: "ekran: 48.6x23.7 mm",
        price2: "kolory: BW",
        reviews: "87",
        url: "Z2.13L.png"
      }
    ],
    Wodoodporne: [
      {
        imageSrc:"Z4.2W.png",
        title: "e-cenówka 4.2''",
        content: "rozmiar: 99.2x89x12 mm",
        price: "ekran: 84.8x63.6 mm",
        price2: "kolory: BW/BWR",
        reviews: "87",
        url: "Z4.2W.png"
      }
    ],
    Akcesoria : [
      {
        imageSrc:"ZU1.png",
        title: "uchwyt typu klips",
        content: "",
        price: "",
        price2: "",
        reviews: "87",
        url: "ZU1.png"
      },
      {
        imageSrc:"ZU2.png",
        title: "stand standardowy",
        content: "",
        price: "",
        price2: "",
        reviews: "87",
        url: "ZU2.png"
      },
      {
        imageSrc:"ZU3.png",
        title: "listwa montażowa",
        content: "",
        price: "",
        price2: "",
        reviews: "87",
        url: "ZU3.png"
      },
      {
        imageSrc:"ZU4.png",
        title: "stand typu szpilka",
        content: "",
        price: "",
        price2: "",
        reviews: "87",
        url: "ZU4.png"
      },
      {
        imageSrc:"ZU5.png",
        title: "uchwyt typu haczyk",
        content: "",
        price: "",
        price2: "",
        reviews: "87",
        url: "ZU5.png"
      }
    ],
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    {/* <CardRatingContainer> */}
                      {/* <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating> */}
                      {/* <CardReview>({card.reviews})</CardReview> */}
                    {/* </CardRatingContainer> */}
                    {/* <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    > */}
                      {/* <CardButton>Buy Now</CardButton> */}
                    {/* </CardHoverOverlay> */}
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                    <CardPrice2>{card.price2}</CardPrice2>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chicken Chilled",
      content: "Chicken Main Course",
      price: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Samsa Beef",
      content: "Fried Mexican Beef",
      price: "$3.99",
      rating: "4.5",
      reviews: "34",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Carnet Nachos",
      content: "Chilli Crispy Nachos",
      price: "$3.99",
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Guacamole Mex",
      content: "Mexican Chilli",
      price: "$3.99",
      rating: "4.2",
      reviews: "95",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chillie Cake",
      content: "Deepfried Chicken",
      price: "$2.99",
      rating: "5.0",
      reviews: "61",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Nelli",
      content: "Hamburger & Fries",
      price: "$7.99",
      rating: "4.9",
      reviews: "89",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Jalapeno Poppers",
      content: "Crispy Soyabeans",
      price: "$8.99",
      rating: "4.6",
      reviews: "12",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Cajun Chicken",
      content: "Roasted Chicken & Egg",
      price: "$7.99",
      rating: "4.2",
      reviews: "19",
      url: "#"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
