import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import MainFeature from "components/features/TwoColWithNoButton.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-black mb-10`;
const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-black text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-1 text-black font-thin`;
  const imageCss = tw`border-white`;
const Text = styled.div`
  ${tw`text-lg  text-black`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Opis rozwiązania" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          {/* <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow> */}
          {/* <Text>
            <p>Serwis do zdalnego zarządzania elektronicznymi etykietami cenowymi (ESL) to zaawansowane oprogramowanie umożliwiające centralne zarządzanie, monitorowanie i aktualizowanie etykiet cenowych w różnych lokalizacjach, często w czasie rzeczywistym. Tego typu serwis jest niezbędny w dużych sieciach detalicznych, aptekach, inteligentnych biurach czy magazynach, gdzie zmiana cen, promocji czy dostępności produktów wymaga szybkiej i efektywnej reakcji.</p>

            <h2>Kluczowe funkcje serwisu do zarządzania elektronicznymi etykietami cenowymi:</h2>
            <p>
            1. Zdalna kontrola i aktualizacja cen: Serwis pozwala na zdalną aktualizację cen, opisów produktów, promocji i innych informacji na wszystkich etykietach w danej sieci czy sklepie. Zamiast ręcznie zmieniać etykiety w każdym punkcie sprzedaży, administrator może zaktualizować ceny w kilku miejscach jednocześnie, oszczędzając czas i eliminując błędy.
            </p>
            <p>
            2. Zarządzanie grupami etykiet: Możliwość grupowania etykiet według kategorii, lokalizacji czy typu produktu pozwala na efektywne zarządzanie i szybsze wprowadzanie zmian w wybranych obszarach. Na przykład, można zmienić ceny tylko w działach z elektroniką, podczas gdy ceny w innych działach pozostaną niezmienione.
            </p>
            <p>
            3. Integracja z systemem ERP i POS: System do zarządzania ESL może być zintegrowany z innymi systemami biznesowymi, takimi jak systemy zarządzania zapasami (ERP), systemy sprzedaży (POS) oraz z systemami e-commerce. Dzięki tej integracji, ceny, dostępność i informacje o produktach są synchronizowane w czasie rzeczywistym, co zapewnia spójność informacji we wszystkich kanałach sprzedaży.
            </p>
            <p>
            4. Harmonogramowanie zmian: Administrator może ustawić harmonogram wprowadzania zmian, co jest przydatne w przypadku promocji, rabatów lub zmian cen w określonych godzinach. Na przykład, ceny mogą być zmieniane automatycznie o godzinie 9:00, a po zakończeniu promocji – wrócić do wcześniejszych wartości.
            </p>
            <p>
            5. Monitorowanie stanu etykiet: Serwis umożliwia monitorowanie stanu technicznego etykiet (np. baterii) oraz wykrywanie ewentualnych problemów z etykietami w czasie rzeczywistym. To ważne, aby mieć pewność, że wszystkie etykiety działają prawidłowo, zwłaszcza w dużych sieciach sklepów.
            </p>
            <p>
            6. Raportowanie i analiza danych: Serwis może generować raporty dotyczące statusu etykiet, zmieniających się cen, trendów sprzedaży czy efektywności promocji. Dzięki analizom, firma może podejmować bardziej świadome decyzje biznesowe, na przykład dostosować ceny w zależności od popytu lub konkurencji.
            </p>
            <p>
            7. Personalizacja wyświetlanych treści: Serwis może umożliwiać personalizowanie wyświetlanych treści, na przykład w zależności od lokalizacji sklepu, grupy docelowej czy sezonu. Można dostosować kolory, czcionki, a także wyświetlać dodatkowe informacje, takie jak kupony rabatowe, promocje czy informacje o dostępności produktów.
            </p>
            <p>
            8. Bezpieczeństwo i uprawnienia: System oferuje kontrolę dostępu do danych i uprawnienia dla różnych użytkowników. Na przykład, menedżerowie mogą mieć dostęp do pełnej funkcjonalności serwisu, podczas gdy pracownicy zajmujący się codziennymi operacjami mogą mieć dostęp tylko do niektórych funkcji, jak np. monitorowanie statusu etykiet.
            </p>

            <h2>Korzyści płynące z używania serwisów do zdalnego zarządzania ESL:</h2>
            <p>
            1. Redukcja kosztów operacyjnych: Automatyczne aktualizowanie cen i informacji pozwala na oszczędność czasu i zasobów, eliminując potrzebę ręcznego zmieniania etykiet w sklepach.
            </p>
            <p>
            2. Zwiększenie efektywności i dokładności: Centralne zarządzanie etykietami pozwala na szybsze i dokładniejsze wprowadzanie zmian, co minimalizuje ryzyko błędów i niezgodności cenowych.
            </p>
            <p>
            3. Lepsze doświadczenie klienta: Przejrzyste, aktualne informacje o cenach i dostępności produktów pomagają klientom szybciej podejmować decyzje zakupowe.
            </p>
            <p>
            4. Optymalizacja przestrzeni: Zdalne zarządzanie etykietami pozwala na łatwe dostosowanie treści w zależności od potrzeb – na przykład, wyświetlanie różnych promocji w różnych lokalizacjach sklepu.
            </p>
            <p>
            5. Skalowalność i elastyczność: Serwisy te są skalowalne i mogą być wykorzystywane w małych sklepach, dużych sieciach detalicznych czy sklepach internetowych, co czyni je elastycznymi i dostosowanymi do różnych modeli biznesowych.
            </p>

            <h2>Podsumowanie:</h2>
            <p>
            Serwis do zdalnego zarządzania elektronicznymi etykietami cenowymi to kluczowy element dla firm, które chcą usprawnić procesy związane z zarządzaniem cenami, dostępnością produktów oraz promocjami w sklepach. Dzięki integracji z systemami ERP, POS oraz innymi technologiami, takie rozwiązania oferują wysoką efektywność, elastyczność i dokładność, co pozwala na osiągnięcie znacznych oszczędności operacyjnych i poprawę doświadczeń klientów.
            </p>
          </Text> */}
          <MainFeature
            subheading={<Subheading></Subheading>}
            heading={
              <>
              Serwis do zdalnego zarządzania ESL. Nowoczesne rozwiązanie dla handlu detalicznego.
              <wbr />
              </>
            }
            description={
              <Description>
                W dzisiejszych czasach efektywne zarządzanie cenami i promocjami jest kluczowe dla sukcesu firm detalicznych. Serwisy do zdalnego zarządzania elektronicznymi etykietami cenowymi (ESL) oferują innowacyjne rozwiązanie, które usprawnia procesy i zwiększa rentowność biznesu.
              </Description>
            }
            buttonRounded={false}
            textOnLeft={false}
            //primaryButtonText="więcej -->"
            //primaryButtonUrl="#BranzaFarmaceutycznaWiecej"
            imageSrc="image1.png"
            imageCss={imageCss}
        // imageDecoratorBlob={true}
        // imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
          />
          <MainFeature
            subheading={<Subheading></Subheading>}
            heading={
              <>
              Integracja dla Pełnej Spójności.
              <wbr />
              </>
            }
            description={
              <Description>
                1. System ERP - Synchronizacja cen i dostępności produktów z systemem zarządzania zapasami.
                <br></br>
                2. System POS - Połączenie z systemem sprzedaży dla aktualizacji cen w czasie rzeczywistym.
                <br></br>
                3. E-commerce - Spójne ceny i informacje o produktach we wszystkich kanałach sprzedaży.
                <br></br>
                4. Redukcja kosztów - Oszczędność czasu i zasobów dzięki automatyzacji procesów.
                <br></br>
                5. Zwiększenie efektywności - Szybsze i dokładniejsze wprowadzanie zmian, co minimalizuje błędy.
                <br></br>
                <br></br>
                Serwisy do zdalnego zarządzania ESL to innowacyjne rozwiązanie dla firm dążących do usprawnienia zarządzania cenami, dostępnością produktów i promocjami. Integracja z systemami ERP, POS i innymi technologiami zapewnia wysoką efektywność, elastyczność i dokładność, co przekłada się na oszczędności operacyjne i lepsze doświadczenia klientów.
              </Description>
              
            }
            buttonRounded={false}
            // textOnLeft={false}
            //primaryButtonText="więcej -->"
            //primaryButtonUrl="#BranzaFarmaceutycznaWiecej"
            imageSrc="image4.png"
            imageCss={imageCss}
        // imageDecoratorBlob={true}
        // imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
          />
          <MainFeature
            subheading={<Subheading></Subheading>}
            heading={
              <>
              Personalizacja wyświetlanych treści.
              <wbr />
              </>
            }
            description={
              <Description>
                Zmieniaj ceny, opisy produktów i promocje w czasie rzeczywistym z dowolnego miejsca, bez konieczności ręcznej aktualizacji. Zarządzaj cenami w różnych lokalizacjach, grupuj etykiety według kategorii, lokalizacji i rodzaju produktu.
                <br></br>
                <br></br>
                1. Lokalizacja - Dostosowywanie treści do konkretnych lokalizacji sklepu.
                <br></br>
                2. Grupa docelowa - Prezentowanie treści dostosowanych do specyficznych grup klientów.
                <br></br>
                3. Sezon - Wyświetlanie informacji i promocji adekwatnych do aktualnego sezonu.
              </Description>
            }
            buttonRounded={false}
            textOnLeft={false}
            //primaryButtonText="więcej -->"
            //primaryButtonUrl="#BranzaFarmaceutycznaWiecej"
            imageSrc="image3.png"
            imageCss={imageCss}
        // imageDecoratorBlob={true}
        // imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
          />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
