import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
//import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm text-primary-700`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Schronisko dla bezdomnych zwierząt w Celestynowie</Subheading>}
        heading="Pomagasz bezbronnym zwierzętom."
        description="Pomagamy ratować setki zwierząt - z lasu, z ulicy, z wypadku, z łańcucha. Przeznaczamy systematycznie 1% naszych dochodów na ten cel. Dołącz do nas, aby zmieniać świat na lepsze."
        buttonRounded={true}
        primaryButtonText="Schronisko Celestynów"
        primaryButtonUrl = "http://www.celestynow.toz.pl/"
        imageSrc="./dogs.jpg"
      />
      <MainFeature1
        subheading={<Subheading>Las na zawsze</Subheading>}
        heading="Sadzisz lasy i chronisz środowisko."
        description="Razem z eWizytówką sadzisz regularnie większy lub mniejszy kawałek lasu. Przeznaczamy systematycznie 1% naszych dochodów na ten cel. Wspieraj Naturę razem z nami."
        buttonRounded={true}
        primaryButtonText="Organizacja Las na zawsze"
        primaryButtonUrl = "https://www.lasnazawsze.org.pl/"
        imageSrc="./trees.jpg"
        textOnLeft={false}
      />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />*/}
      <Footer />
    </AnimationRevealPage>
  );
};
