import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
//import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm text-black`;
const Heading = tw.span`text-black`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>województwo pomorskie</Subheading>}
        heading={<Heading>Apteka w Szczecinie</Heading>}
        description = "70-893 Szczecin ul. Tartaczna 14"
        // buttonRounded={false}
        primaryButtonText="obejrzyj film -->"
        primaryButtonUrl = "https://www.youtube.com/embed/cqlz8MyfB3o"
        imageSrc="./tartaczna.jpg"
        />
      <MainFeature1
        subheading={<Subheading>województwo małopolskie</Subheading>}
        heading={<Heading>Apteka w Inwałdzie</Heading>}
        description = "34-120 Andrychów Inwałd ul. Miła 9"
        // buttonRounded={false}
        primaryButtonText="brak filmu -->"
        primaryButtonUrl = "#"
        imageSrc="./inwałd.jpg"
        textOnLeft={false}
      />
      <MainFeature1
        subheading={<Subheading>województwo małopolskie</Subheading>}
        heading={<Heading>Apteka w Gilowicach</Heading>}
        description = "34-322 Gilowice ul. Krakowska 38"
        // buttonRounded={false}
        primaryButtonText="brak filmu -->"
        primaryButtonUrl = "#"
        imageSrc="./gilowice.jpg"
      />
      {/* <MainFeature1
        subheading={<Subheading>województwo pomorskie</Subheading>}
        heading={<Heading>Apteka w Szczecinie</Heading>}
        description = "70-893 Szczecin ul. Tartaczna 14"
        // buttonRounded={false}
        primaryButtonText="obejrzyj film -->"
        primaryButtonUrl = "https://www.youtube.com/embed/cqlz8MyfB3o"
        imageSrc="./tartaczna.jpg"
      /> */}
      {/* <MainFeature1
        subheading={<Subheading>KROK 3 - utrzymanie i serwis</Subheading>}
        heading="Jesteśmy tutaj aby pomagać."
        description ="Dokładamy wszelkich starań, aby nasze aplikacje działały bez zarzutu. Jeśli potrzebujesz zaktualizwoać swoje dane lub potrzebujesz porady dotyczącej Twojej eWizytówki skontaktuj się z naszym ekspertem."
        // buttonRounded={false}
        primaryButtonText="Serwis 24/7"
        primaryButtonUrl = "/serwis"
        imageSrc = "./serwis.jpg"
        textOnLeft={false}
      /> */}
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
